import React from "react";
import Layout from "../components/Layout/layout"
import { useForm } from "react-hook-form";
import { useState } from 'react';

export default function App() {
  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm();
  const { formState: {isSubmitting}} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const GATEWAY_URL = 'https://kl0wc6pvpk.execute-api.eu-west-1.amazonaws.com/prod/submit';
  const onSubmit = data => {
  try {
    fetch(GATEWAY_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    reset();
    alert('Bedankt voor uw boodschap. Uw email werd verzonden. Wij nemen spoedig contact met u op.')
    setSubmitted(true);
  } catch (error) {
    setError('submit', 'submitError', `Doh! ${error.message}`);
  }
};
  
  
  /* const onSubmit = data => console.log(data); */

  return (
	<Layout page={"contact"}>
        <div className="main">
          <h1 className="title">Contactformulier</h1>
          	<div className="text md:w-2/6">      	
			    <form onSubmit={handleSubmit(onSubmit)} class="w-full max-w-lg">
					<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
						<div className="md:w-1/3 min-w-full">
					      <label htmlFor="name" class="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">Naam</label>
					    </div>
					    <div className="md:w-2/3 min-w-full">  
					      <input
					      	className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					        id="name"
					        aria-invalid={errors.firstName ? "true" : "false"}
					        {...register('name', { required: true })}
					      />
					      {errors.name && (
					        <span role="alert">
					          Dit veld is verplicht.
					        </span>
					      )}
						</div>
					</div>
					
					<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
						<div className="md:w-1/3 min-w-full">
					      <label htmlFor="email" class="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">Email</label>
					     </div>
					    <div className="md:w-2/3 min-w-full">  
					      <input
					        id="email"
					        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					        aria-invalid={errors.email ? "true" : "false"}
					        {...register('email', { required: true })}
					      />
					      {errors.email && (
					        <span role="alert">
					          This field is required
					        </span>
					      )}
						</div>
					</div>
					
					<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
						<div className="md:w-1/3 min-w-full">
					      <label htmlFor="phone" className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">Telefoon</label>
					    </div>
					    <div className="md:w-2/3 min-w-full">  
					      <input
					        id="phone"
					        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
					        aria-invalid={errors.phone ? "true" : "false"}
					        {...register('phone', { required: false, maxLength: 30  })}
					      />
					      {errors.phone && (
					        <span role="alert">
					          Dit veld is verplicht.
					        </span>
					      )}
						</div>
					</div>
					
					<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
						<div className="md:w-1/3 min-w-full">
					      <label htmlFor="desc" className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">Boodschap</label>
					    </div>
					    <div className="md:w-2/3 min-w-full">  
					      <textarea
						  {...register('desc', { required: true, maxLength: 800  })}
						  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						  name="desc"
						  id="desc"
						  rows="3"
						  placeholder="Uw boodschap"
						  />
					      {errors.desc && (
					        <span role="alert">
					          Dit veld is verplicht. 
					        </span>
					      )}
						</div>
					</div>
					
					<div className="flex flex-wrap -mx-3 mb-6 md:justify-center">
						<div className="md:w-1/3 min-w-full">
						</div>
						<div className="md:w-2/3 min-w-full"> 
							<div>
						      <input 
							  disabled={isSubmitting} 
							  type="submit" 
							  className="block w-full mb-10 mt-8 border-2 rounded px-6 py-1 bg-green-500 hover:bg-green-700 text-white"
							  value="Versturen"
							  />
							</div>
						</div>
					</div>
			    </form>
	    </div>
	        
		<div className="bg-gray-50">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
			
			 <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
		  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">DEZE WEBSITE IS EEN INITATIEF VAN CHRIS LENAERTS</h2>	 
		  <p></p>
		  <br></br>
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADRES</h2>
          <p className="leading-relaxed">Venneborglaan 70, 2100 Deurne</p>
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ONDERNEMINGSNUMMER</h2>
          <p className="leading-relaxed">0771798514</p>
            <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">TELEFOONNUMMER</h2>
          <p className="leading-relaxed">+32 495 355 260</p>
        </div>
		
			<div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
			
			</div>
		</div>
		</div>	    		    
		</div>		
	</Layout>
  );
}